@import "variables.less";
@import "mixins.less";


//-----------------------

//[class*='col-'] {
//    padding-right:0;
//    padding-left:0;
//    margin: 0;
//}

body, html {
    font-family: 'Roboto', sans-serif;
    text-rendering:optimizeLegibility !important;
    -webkit-font-smoothing:antialiased !important;
    color:@theme-grey;
    background-color: @theme-grey;
    // Global Components
    height: 100%;
    width: 100%;
}

html {
    //overflow-y: hidden !important;
}

h1 {
    font-weight:700;
    strong {
        font-weight:900;
    }
}
h2 {
    font-size:36px;
    margin:0;
}
h3 {
    font-size:16px;
    font-weight:700;
}
h5 {
    text-transform:uppercase;
    font-weight:700;
    line-height:20px;
}
p {
    font-family: 'Roboto', sans-serif;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

  font-size: 1.5em;

    @media (max-width: @screen-sm) {
      font-size: 1.25em;
    }
    @media (max-width: @screen-sm) {
      font-size: 1em;
    }

    @media (max-width: @screen-xs) {
      font-size: 0.75em;
    }
}
.container{
    width: auto;
}
a {
    color:@theme-primary;
    transition:all 0.3s;
}
a:hover {
    text-decoration:none;
    color:lighten(@theme-primary, 20%);
    border-bottom: 1px solid @theme-primary;
}
a:focus {
    text-decoration:none;
    color:darken(@theme-primary, 10%);
    outline:none;
    outline-offset:none;
}
.img-centered {
    margin:0 auto;
}


ul, ol {
    list-style:none;
}
.clearfix:after {
    visibility:hidden;
    display:block;
    font-size:0;
    content:" ";
    clear:both;
    height:0;
}
.clearfix {
    display:inline-block;
    display:block;
}
* html .clearfix {
    height:1%;
}
#preloader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:@theme-grey;
    z-index:99999;
    height:100%;
}

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: @theme-primary;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: @theme-white;

        -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    &:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: @theme-primary;

        -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}

.section-title {
    padding:30px 0;
    h2 {
        font-weight:400;
        margin-bottom:26px;
    }
}
hr {
    height:3px;
    width:60px;
    position:relative;
    background:@theme-primary;
    border:0;
    margin-bottom:30px;
}
#menu {
    padding:20px;
    transition:all 0.8s ease-in-out;
    a.navbar-brand {
        text-transform:uppercase;
        font-size:22px;
        color:@theme-white;
        font-weight:700;
        letter-spacing:1px;
        i.fa {
            color:@theme-primary;
        }
    }


    @media (max-width: @screen-sm) {
        .on;
        padding:0 !important;
    }


    //.b-shadow-2;
}
#menu.navbar-default {
    background-color:rgba(248,248,248,0);
    //background-color: @theme-primary;
    border-color:rgba(231,231,231,0);

    .container{
        margin: 0 2em;

        @media (max-width: @screen-sm) {
            margin: 0;
        }
    }

    .navbar-header{

    }

    &.solid{
        background-color: @theme-white !important;
    }

    .navbar-nav > li > a {
        text-transform:uppercase;
        color: @theme-grey;
        font-size:12px;
        letter-spacing:1px;
    }
    .navbar-nav > li > a:hover {
        color:@theme-grey-light;
    }




    .navbar-collapse{
        border: none;

        ul{
            li{

                @media (max-width: @screen-sm) {
                    padding: 0.5em 1em;

                }
            }

        }
    }
}
.on {
    background-color:@theme-white !important;
    padding:1em 0 !important;
    .navbar-nav > li > a {
        color: @theme-grey !important;
    }
    .navbar-brand p {
        color: @theme-grey !important;
    }
}
.navbar-default {

    .navbar-nav > .active {
        > a, > a:hover, > a:focus {
            color:@theme-primary !important;
            //border-bottom: 1px solid @theme-primary;
            background-color:transparent;

            @media (max-width: @screen-sm) {
                border-bottom: none;
                color: @theme-grey-light;
            }
        }
    }

    .navbar-toggle {

        border:none;
        border-radius:0;
        .icon-bar{
            -webkit-transition: all 0.3s ease-in-out ;
            -moz-transition: all 0.3s ease-in-out ;
            -ms-transition: all 0.3s ease-in-out ;
            -o-transition: all 0.3s ease-in-out ;
            transition: all 0.3s ease-in-out ;
            background-color: @theme-grey;
        }

        &:hover{
            background-color:rgba(248,248,248,0) !important;
            -webkit-transition: all 0.3s ease-in-out ;
            -moz-transition: all 0.3s ease-in-out ;
            -ms-transition: all 0.3s ease-in-out ;
            -o-transition: all 0.3s ease-in-out ;
            transition: all 0.3s ease-in-out ;
            transform: scale(1.3);
            .icon-bar{

            }
        }
    }



}

header {
    position: relative;
    width: 100%;
    background:url(../img/coc_header.jpg);
    //background-size:cover;
    //background-attachment:fixed;
    background-position:center;
    .background-cover;


    text-align: center;
    color: @theme-grey;
    padding-top: 70px;
    min-height: 100vh;
    //min-height: 100%;

    //.b-shadow-5;

    //display: -webkit-flex !important;
    //display: flex !important;
    //-webkit-flex-direction: column !important;/* works with row or column */
    //flex-direction: column !important;
    //-webkit-align-items: center !important;
    //align-items: center !important;
    //-webkit-justify-content: center !important;
    //justify-content: center !important;



    .header-content {
        //position: absolute;
        //top: 50%;
        //-webkit-transform: translateY(-50%);
        //-ms-transform: translateY(-50%);
        //transform: translateY(-50%);
        //padding: 0 50px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .header-content-inner {
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column ;/* works with row or column */
            flex-direction: column;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;

            p {
                color: @theme-grey;
                font-size: 18px;
                //max-width: 80%;
                margin-left: auto;
                margin-right: auto;
                white-space: nowrap;
            }


            img {
                max-width: 40%;
            }


          .num{
            margin-top: 0.5em;
          }

        }
    }


}

#landing-title{
    text-transform: uppercase;
    color: @theme-grey;
    .no-margins;
    white-space: nowrap;
    font-size: 3em;
    letter-spacing: 0.1em;
    font-weight: 300;

    @media (max-width: @screen-md) {
        font-size: 2.5em !important;
    }

    @media (max-width: @screen-sm) {
        font-size: 2em !important;
    }

    @media (max-width: @screen-xs) {
        font-size: 1.1em !important;
    }

}



#landing-sub-title{
    text-transform: uppercase;
    color: @theme-grey;
    .no-margins;
    letter-spacing: 0.1em;

}

.btn{
    border-radius: 0;
    padding: 6px 14px;
    margin-top: 20px;
    font-size: 1.2em;
    text-transform: uppercase;
    transition: all 0.5s;
    letter-spacing: 1px;
}

.btn-default {
    background: transparent;
    border: 2px solid @theme-white;
    color: @theme-white;
}


.btn-dark{
    background: transparent;
    border: 2px solid @theme-grey;
    color: @theme-grey;
}

.btn-lg{
    padding: 0.5em 1em;
    font-size: 1.5em;
}

.btn-link{
  color: @theme-primary;

  &:hover{
  color: darken(@theme-primary,20%);
  }
}



.btn-default:hover, .btn-default:focus, .btn-default.focus, .btn-default:active, .btn-default.active {
    background-color:@theme-white;
    color: @theme-grey;
    border-color:@theme-white ;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark.focus, .btn-dark:active, .btn-dark.active {
    background-color:@theme-grey;
    color: @theme-white;
    border-color:@theme-grey ;
}

.space {
    margin-top:40px;
}


footer {
  background:@theme-grey;
  padding:5px 0;
  color:@theme-white;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column; /* works with row or column */
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.fullscreen,
.content-a {
    width:100%;
    height:100%;
    overflow:hidden;
}
.fullscreen.overflow,
.fullscreen.overflow .content-a {
    height:auto;
    min-height:100%;
}

/* content centering styles */
.content-a {
    display:table;
}
.content-b {
    display:table-cell;
    position:relative;
    vertical-align:middle;
    text-align:center;
}



.contactLoading{
    margin-top: 2em;
}




label {
    font-size:12px;
    font-weight:400;
    float:left;
}
.btn:active, .btn.active {
    background-image:none;
    outline:0;
    -webkit-box-shadow:none;
    box-shadow:none;
}
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline:none;
    outline-offset:none;
}
#footer {
    background:@theme-grey;
    padding:15px 0 10px 0;
    color:@theme-white;
    text-align:center;
    p {
        margin-top:10px;
    }
}


.alignCenter{
    display: block;
    margin-left: auto;
    margin-right: auto;
}



.scroller{
    position:absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.pointers{
    width:28px;
    margin-top:0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;

}


.pointers > span
{
    width: 10px;
    height: 10px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);

    border-right: 2px solid @theme-primary;
    border-bottom: 2px solid @theme-primary;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.unu
{
    //margin-top: 12px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
}

.unu
{
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
}

.doi
{
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
}

.trei
{
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;
}


.mouse
{
    height: 42px;
    width: 28px;
    border-radius: 20px;
    transform: none;
    border: 3px solid @theme-primary;
    top: 170px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    //background-color: @theme-primary !important;
    //.b-shadow-2;

}

.wheel
{
    height: 10px;
    width: 4px;
    display: block;
    margin: 7px auto;
    background: @theme-primary;
    position: relative;
}

.wheel
{
    -webkit-animation: mouse-wheel 1.2s ease infinite;
    -moz-animation: mouse-wheel 1.2s ease infinite;
}

@-webkit-keyframes mouse-wheel
{
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
        transform: translateY(6px);
    }
}

@-moz-keyframes mouse-wheel
{
    0% { top: 1px; }
    50% { top: 2px; }
    100% { top: 3px;}
}

@-webkit-keyframes mouse-scroll {

    0%   { opacity: 0;}
    50%  { opacity: .5;}
    100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}
@keyframes mouse-scroll {

    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
}

.navbar-brand{

    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row ;/* works with row or column */
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;


    @media (max-width: @screen-md) {

        p{
            display: none !important;
        }

    }


    > img {
        display: inline-block;
        max-width: 2em;
    }

    > p {
        display: inline-block;
        text-transform: uppercase;
        font-size: 16px;
        color: @theme-grey;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 !important;
    }
}



.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

// Bootstrap Overrides
.text-primary {
    color: @theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}


.no-margins{
    margin: 0 !important;
}

.no-padding{
    padding: 0 !important;
}




//loader
.loading-sm-container{
    display: inline-block;
    width: 100px;
    height: 100px;
}

.loading {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: @theme-primary;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: @theme-white;

        -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    &:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: @theme-primary;

        -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}

.text-success{
    color: @theme-success !important;
}


.text-primary{
    color: @theme-primary-blue !important;
}


.text-warning{
    color: @theme-warning !important;
}


.text-danger{
    color: @theme-danger !important;
}
.text-muted{
    color: @theme-grey-lightest !important;
}

.alert-success {
    .alert-variant(@theme-success; @theme-white);
    border:none;
    border-radius: 0;
}

.alert-primary {
    .alert-variant(@theme-primary-blue; @theme-white);
}

.alert-warning {
    .alert-variant(@theme-warning; @theme-white);
}

.alert-danger {
    .alert-variant(@theme-danger; @theme-white);
}

.fb-page{
    margin-bottom: 1em;
}



.b-shadow-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.2s ease-in-out;
}

.b-shadow-1:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.b-shadow-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.b-shadow-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.b-shadow-3-light {
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.19), 0 6px 6px rgba(255, 255, 255, 0.23);
}

.b-shadow-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.b-shadow-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}



#about-section{

    position: relative !important;
    background-color: @theme-grey;
    //background:url(../img/coc_hall.jpg);
    //background-size:cover;
    //background-attachment:fixed;
    overflow: visible;
    z-index: 11;
    color: @theme-white;

    //overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column; /* works with row or column */
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;



    .side-right{
        //padding: 10vh;
        max-width: 850px;
        margin: 0 4em;
        background-color: @theme-white;
        height: auto;

        @media (max-width: @screen-sm) {
            padding: 7.5vh;
            margin: 0 2em;

        }

        @media (max-width: @screen-xs) {
            padding: 5vh;
            transform: translate(0,0);
            margin: 0;
        }

        p{
            color: @theme-grey;
        }

        .title{
            color: @theme-grey;
        }

        transform: translateY(4em);

    }


    .side-left{
        margin:2em;
        padding: 10vh;
        height:100vh;
        padding-right: calc(10vh + 4em);

        @media (max-width: @screen-sm) {
            padding-right: 0 ;
            padding: 5vh;
        }


        p{
            font-size: 1.5em;
            letter-spacing: 0.05em;
        }
    }


}


#experience-section{
    //padding: 10vh;
    position: relative !important;
    background-color: @theme-white;
    overflow: visible;
    width: 100%;
    //min-height: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column; /* works with row or column */
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;

    color: @theme-grey;



    @media (max-width: @screen-md) {

        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        //@media (max-width: @screen-sm) {
        //    padding: 7.5vh;
        //
        //}
        @media (max-width: @screen-sm) {
            padding: 7.5vh;
        }

        @media (max-width: @screen-xs) {
            padding: 5vh;
        }
    }



    h1{
        -webkit-align-self: flex-start;
        align-self: flex-start;
    }

}





#treatments-section {
    position: relative !important;
    background-color: @theme-grey;
    overflow: visible;
    width: 100%;
    //min-height: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column; /* works with row or column */
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;

    color: @theme-white;


    h1{
      color: @theme-white;
    }



    #lab_snippet_module .col-lg-3,
    #lab_snippet_module .col-md-4,
    #lab_snippet_module .col-sm-6,
    #lab_snippet_module .col-xs-12 {
        padding: 0!important;
    }

    #lab_snippet_module {
        background: purple;
        padding: 50px 0;
    }

    .hover,
    .hover h2 {
        text-align: center
    }

    .hover,
    .hover .overlay {
        width: 100%;
        height: 100%;
        overflow: hidden;
        max-width: 500px;
    }



    .hover button.info,
    .hover h2 {
        text-transform: uppercase;
        color: #fff
    }

    .col-lg-3,
    .col-md-4,
    .col-sm-6,
    .col-xs-12 {
        padding: 0
    }

    .hover {
        float: left;
        position: relative;
        cursor: default
    }

    .hover .overlay {
        position: absolute;
        top: 0;
        left: 0
    }

    .hover img {
        display: block;
        position: relative;
        background-position:center;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        background-size: cover !important;
        -o-background-size: cover !important;

    }

    .ehover10 button,
    .hover button.info {
        display: inline-block
    }

    .hover h2 {
        position: relative;
        font-size: 17px;
        padding: 10px;
        background: rgba(0, 0, 0, .6)
    }

    .hover button.info {
        text-decoration: none;
        padding: 7px 14px;
        border: 1px solid #fff;
        margin: 50px 0 0;
        border-radius: 0;
        background-color: transparent
    }

    .hover button.info:hover {
        box-shadow: 0 0 5px #fff
    }

    .ehover5 button.info:hover,
    .hover button.nullbutton:hover {
        box-shadow: none
    }

    .hover button.nullbutton {
        border: none;
        padding: 0;
        margin: 0
    }

    .ehover4 button.info {
        margin: -55px 0 0;
        padding: 73px 90px;
        font-weight: 400;
        border: 1px solid #fff
    }

    .modal-open .modal,
    button:focus {
        outline: 0!important
    }

    .point {
        cursor: pointer
    }



    .ehover4 .overlay,
    .ehover4 h2 {
        -webkit-transition: all .4s ease-in-out
    }


    .ehover4 h2 {
        -webkit-transform: translateY(55px);
        -ms-transform: translateY(55px);
        transform: translateY(55px);
        transition: all .4s ease-in-out
    }

    .ehover4:hover h2 {
        -webkit-transform: translateY(15px);
        -ms-transform: translateY(15px);
        transform: translateY(15px)
    }

    .ehover4 .overlay {
        background-color: rgba(75, 75, 75, .7);
        transition: all .4s ease-in-out
    }

    .ehover4:hover .overlay {
        //background-color: rgba(48, 152, 157, .4)
        background-color: rgba(96, 126, 140, 0.4)
    }

    .ehover4 button.info {
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out
    }

    .ehover4:hover button.info {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }










}


h1.title {
    font-size: 2.5em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 500;
    white-space:nowrap;
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: @screen-md) {
        font-size: 2em !important;
        font-weight: 600;
    }


    @media (max-width: @screen-sm) {
        font-size: 1.5em !important;
        font-weight: 700;
    }


    @media (max-width: @screen-sm) {
        font-size: 1.3em !important;
        font-weight: 700;
    }
}

h2.sub-title {
    font-size: 2em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 400;

    @media (max-width: @screen-md) {
        font-size: 1.5em !important;
    }


    @media (max-width: @screen-sm) {
        font-size: 1.2em !important;
    }
}





#cta-section {
    position: relative !important;
    background:url(../img/coc_entrance.jpg);
    background-position:center;
    .background-cover;
    overflow: hidden;
    width: 100%;
    //min-height: 50vh;

    color: @theme-white;

    h1{
      color: @theme-white;
    }

    .content{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column; /* works with row or column */
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
    }

    .title{
        margin-bottom: 0.5em !important;
    }

    .sub-title{
        color: @theme-grey-lightest;
    }

}


.testimonial {
    position: relative !important;
    background:url(../img/coc_office.jpg);
    background-position:center;
    .background-cover;
    overflow: hidden;
    width: 100%;
    color: @theme-grey;


    .content{
        //text-align: center;
    }

    @media (max-width: @screen-sm) {
        //padding: 5vh 0;
    }


    .quote{
        font-size: 2em;
        letter-spacing: 0.1em;
        //text-transform: uppercase;
        font-weight: 400;
        text-align: left;



      @media (max-width: @screen-md) {
            font-size: 1.5em !important;
        }


        @media (max-width: @screen-sm) {
            font-size: 1.2em !important;
        }

        &.quote-md{
            font-size: 1.5em;



            @media (max-width: @screen-md) {
                font-size: 1.2em !important;
            }


            @media (max-width: @screen-sm) {
                font-size: 0.9em !important;
            }
        }

      small{
        float: right;
      }


    }




}

#contact-section1 {
    position: relative !important;
    background-color: @theme-white;
    overflow: visible;
    width: 100%;

    color: @theme-grey;



    .content{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column; /* works with row or column */
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin-top: 5em !important;
    }



}

#where-are-we{
    display: block;
    position: relative;
    height: calc(33%);
    width: 100%;


    #map{
        display: block;
        position: relative;
        //height: calc(33%);
        height: 100%;
        width: 100%;
    }
}




#contact-section {
    background-color: @theme-white;
    display: block;
    position: relative;
    //.gradient(@theme-primary, @theme-primary, lighten(@theme-primary, 10%));
    //background:darken(@theme-primary, 20%);
    //background:-moz-linear-gradient(top,rgba(193,166,24,1) 0%,rgba(241,196,15,1) 25%,rgba(241,196,15,1) 75%,rgba(193,166,24,1) 100%);
    //background:-webkit-linear-gradient(top,rgba(193,166,24,1) 0%,rgba(241,196,15,1) 25%,rgba(241,196,15,1) 75%,rgba(193,166,24,1) 100%);
    //background:linear-gradient(to bottom,rgba(193,166,24,1) 0%,rgba(241,196,15,1) 25%,rgba(241,196,15,1) 75%,rgba(193,166,24,1) 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='darken(@theme-primary, 20%)',endColorstr='darken(@theme-primary, 20%)',GradientType=0);

    form {
        padding:30px 0;
    }
    .fa {
        color:@theme-primary;
        margin-bottom:10px;
    }
    .form-control {
        display:block;
        width:100%;
        padding:6px 12px;
        font-size:14px;
        line-height:1.42857143;
        color:#555555;
        background-color:#ffffff;
        background-image:none;
        border:1px solid #cccccc;
        border-radius:2px;
        -webkit-box-shadow:none;
        box-shadow:none;
        -webkit-transition:none;
        -o-transition:none;
        transition:none;
    }
    .form-control:focus {
        border-color:inherit;
        outline:0;
        -webkit-box-shadow:transparent;
        box-shadow:transparent;
    }
    .social {
        margin-top:10px;
        ul {
            -webkit-padding-start:0 !important; ;
        }
        ul li {
            display:inline-block;
            margin:0 20px;
        }
        i.fa {
            font-size:50px;
            padding:4px 5px;
            color:#3b5998;
            transition:all 0.5s;
        }
        i.fa:hover {
            color: #5282d8;
        }
    }

    .text-danger {
        color:#cc3300;
        text-align:left;
    }
}




.large-text-block{
    font-size: 2.5em;
    font-weight: 200;
}



[data-href]
{
    cursor:pointer;
}




.group {
    position:relative;
    margin:45px 0;
}
textarea {
    resize:none;
    background:none;
    color:@theme-grey;
    font-size:18px;
    padding:10px 10px 10px 5px;
    display:block;
    //width:320px;
    border:none;
    border-radius:0;
    border-bottom:1px solid #c6c6c6;
    width:100%;
}
input {
    background:none;
    color:@theme-grey-light;
    font-size:18px;
    padding:10px 10px 10px 5px;
    display:block;
    width:100%;
    border:none;
    border-radius:0;
    border-bottom:1px solid #c6c6c6;
}
input:focus, textarea:focus {
    outline:none;
}
input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label,input[aria-invalid="true"] ~ label {
    top:-14px;
    font-size:12px;
    color:@theme-grey-blue;
}
input:focus ~ .bar:before, textarea:focus ~ .bar:before {
    width:100%;
}
input[type="password"] {
    letter-spacing:0.3em;
}
label {
    color: #717171;
    font-size:16px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    -webkit-transition:300ms ease all;
    transition:300ms ease all;
}
.bar {
    position:relative;
    display:block;
    width:100%;
}
.bar:before {
    content:'';
    height:2px;
    width:0;
    bottom:0px;
    position:absolute;
    background:@theme-grey-blue;
    -webkit-transition:300ms ease all;
    transition:300ms ease all;
    left:0%;
}


.otherLink{
    color: @theme-white;
    font-size: 1.75em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 500;
    //white-space:nowrap;
    text-align: center;
    margin: 1em 0;
    @media (max-width: @screen-md) {
        font-size: 1.25em !important;
        font-weight: 600;
    }


    @media (max-width: @screen-sm) {
        font-size: 1.1em !important;
    }

    @media (max-width: @screen-sm) {
        font-size: 0.9em !important;

    }


    }



.centerAll{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column ;/* works with row or column */
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}


.bg-primary{
    background-color: @theme-primary;
}


.bg-primary-hover{
    background-color: @theme-primary;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover{
        background-color: lighten(@theme-primary,10%) !important;
        cursor: pointer;
    }
}

.bg-grey{
    background-color: @theme-grey;
}


.bg-grey-hover{
    background-color: @theme-grey;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover,:active{
        background-color: lighten(@theme-grey,10%) !important;
        cursor: pointer;
    }
}


#FAQ,#whatis{
    position: relative !important;
    overflow: visible;
    width: 100%;
    //min-height: 50vh;
    padding: 10vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column; /* works with row or column */
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    //-webkit-justify-content: center;
    //justify-content: center;
    min-height: 100vh;
    color: @theme-grey;


    background-color: @theme-white;

    @media (max-width: @screen-sm) {
        padding: 7.5vh;


        .content{

            .title{
                font-size: 2em !important;
            }


            .question{
                font-size: 1.2em !important;;
            }


            .answer{
                font-size: 0.8em !important;;
            }
        }

    }

    @media (max-width: @screen-xs) {
        padding: 5vh;
        .content{
            margin-top: 50px;

            .title{
                font-size: 1em !important;
            }


            .question{
                font-size: 1em !important;
            }


            .answer{
                font-size: 0.65em !important;
                letter-spacing: 1px;
            }
        }

    }

    p{
        color: @theme-grey;
        font-size: 1.5em;
        letter-spacing: 0.05em;
    }

    .title{
        color: @theme-grey;
    }



    .content{
        //margin-top: 100px;

        width:100%;

        .question{
            font-size: 1.4em;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 500;
            margin-top: 1.5em;
        }


        .answer{
            font-size: 1em;
            letter-spacing: 2px;
            //text-transform: uppercase;
            font-weight: 400;

        }

    }
    //transform: translateY(4em);
}

.circular-menu {
    z-index: 50;
    width:150px;
    height:150px;
    margin:0 auto;
    position: fixed;
    bottom: -40px;
    left: -40px;
}
.circle {
    width:150px;
    height:150px;
    opacity:0;
    -webkit-transform:scale(0);
    -moz-transform:scale(0);
    transform:scale(0);
    -webkit-transition:all 0.4s ease-out;
    -moz-transition:all 0.4s ease-out;
    transition:all 0.4s ease-out;
    a {
        text-decoration:none;
        color:@theme-primary;
        display:block;
        height:40px;
        width:40px;
        line-height:40px;
        margin-left:-20px;
        margin-top:-20px;
        position:absolute;
        text-align:center;


        &:hover{
            border-bottom: none !important;
        }

    }
    a:hover:not(.num) {
        cursor: pointer;
        color: darken(@theme-primary,10%);
    }

    .num{
        font-size: 15px;
        position: absolute !important;
        top: -2px !important;
        right: 10px !important;
        display: inline-block;
        white-space: nowrap;
    }

    .fa-stack{
        .fa-stack-1x{
            margin-top: 10px;
            margin-left: 10px;
            color: #00abff;
        }
    }
}
.open.circle {
    opacity:1;
    -webkit-transform:scale(1);
    -moz-transform:scale(1);
    transform:scale(1);
}



.menu-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    text-decoration:none;
    text-align:center;
    color:#444444;
    border-radius:50%;
    display:inline-block;
    height:40px;
    width:40px;
    cursor: pointer;
    //line-height:40px;
    //padding:10px;
    background-color: #ffffff;

    box-shadow:0 2px 5px 0 rgba(0,0,0,0.26);

    transition: all 0.3s ease-in-out;

    &:hover{
        .fa{
            font-size: 18px;
        }
    }

    .fa{
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        font-size: 23px;
    }

    &.open{

        .fa{
            font-size: 18px !important;
        }
    }
}
.menu-button:hover {
    background-color:@theme-white;
}

#open-sign{
    margin: 1em;
    padding: 0.5em 1em;
    background: @theme-grey;
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 45px;
    color: @theme-white;
    overflow: hidden;


    .transition(all 0.3s ease-in-out);
    transition: all 0.3s ease-in-out;



    &:hover{
        cursor: default;

    }


    &.open{
        height: 310px;

        #opening-hours{
            .transition(all 2s ease-in-out);
            transition: all 2s ease-in-out;
            display: block;
            opacity: 1;
            //-webkit-transform:scale(1);
            //-moz-transform:scale(1);
            //transform:scale(1);
        }

    }

    &.closed{
        border: 0;

    }

    hr{
        background-color: @theme-primary;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }


    span{
        font-weight: 400;
        font-size: 1.6em;
    }

}


#opening-hours{
    .transition(all 2s ease-in-out);
    transition: all 2s ease-in-out;
    display: none;
    opacity: 0;
    overflow: hidden;
}

.opening-hours{
    transition: all 1s ease-in-out;

    display: block;

    @media (max-width: 600px) {
        margin: 0;
    }

    tr{

        th{
            text-align: center;
            padding: 1em;
        }

        td{
            padding: 0.3em;
            text-align: left;

            &:first-of-type{
                font-weight: 900;
            }
        }
    }
}


.section{

    padding: 10vh;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    //@media (max-width: @screen-sm) {
    //    padding: 7.5vh;
    //
    //}
        @media (max-width: @screen-sm) {
        padding: 7.5vh;
    }

    @media (max-width: @screen-xs) {
        padding: 5vh;
    }


    h1{
      font-size: 2.5em;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1em;
      color: @theme-grey;

      @media (max-width: @screen-md) {
        font-size: 2em !important;
        font-weight: 600;
      }


      @media (max-width: @screen-sm) {
        font-size: 1.5em !important;
        font-weight: 700;
      }


      @media (max-width: @screen-sm) {
        font-size: 1.3em !important;
        font-weight: 700;
      }

    }


    h2{
      font-size: 2em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 400;
      color: @theme-grey;
      text-align: center;

      @media (max-width: @screen-md) {
        font-size: 1.5em !important;
      }


      @media (max-width: @screen-sm) {
        font-size: 1.2em !important;
      }
    }
}

h1 small{
  color: @theme-grey-light;
}

.num{
  font-size: 1.25em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 400;
  color: @theme-grey;
  text-align: center;

  @media (max-width: @screen-md) {
    font-size: 1em !important;
  }


  @media (max-width: @screen-sm) {
    font-size: 0.75em !important;
  }
}




